<template>
  <div>
    <div class="block banner bg">
      <div class="container">
        <div class="box">
          <h1>{{$t('solution106')}}</h1>
          <h2>{{$t('solutionGame')}}</h2>
          <p class="sm">{{$t('home68')}}</p>
          <p class="sm">{{$t('solution107')}}</p>
          <el-button type="primary">{{$t("contactUs")}}</el-button>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <h2>
          {{$t('solution103')}}
        </h2>
        <ul class="list1">
          <li class="icon30">
            <h5>{{$t('solution104')}}</h5>
            <p>{{$t('solution105')}}</p>
          </li>
          <li class="icon31">
            <h5>{{$t('solution108')}}</h5>
            <p>{{$t('solution109')}}</p>
          </li>
          <li class="icon43">
            <h5>{{$t('solution110')}}</h5>
            <p>{{$t('solution111')}}</p>
          </li>
          <li class="icon45">
            <h5>{{$t('solution112')}}</h5>
            <p>{{$t('solution113')}}</p>
          </li>
        </ul>
        <h2>
          {{$t('solution49')}}
        </h2>
        <ul class="list1 three">
          <li class="icon1">
            <h5>{{$t('productSdk')}}</h5>
            <p>{{$t('product3')}}</p>
            <a>{{$t('learnmore')}} ></a>
          </li>
          <li class="icon2">
            <h5>{{$t('productScdn')}}</h5>
            <p>{{$t('product51')}}</p>
            <a>{{$t('learnmore')}} ></a>
          </li>
          <li class="icon3">
            <h5>{{$t('home36')}}</h5>
            <p>{{$t('home37')}}</p>
            <a>{{$t('learnmore')}} ></a>
          </li>
          <li class="icon4">
            <h5>{{$t('productWaf')}}</h5>
            <p>{{$t('solution114')}}</p>
            <a>{{$t('learnmore')}} ></a>
          </li>
          <li class="icon5">
            <h5>{{$t('productDns')}}</h5>
            <p>{{$t('home50')}}</p>
            <a>{{$t('learnmore')}} ></a>
          </li>
          <li class="icon6">
            <h5>{{$t('productSsl')}}</h5>
            <p>{{$t('home57')}}</p>
            <a>{{$t('learnmore')}} ></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="block gray">
      <div class="container">
        <h2>{{$t('solution115')}}</h2>
        <ul class="product-list child">
          <li>
            <div class="img"><img src="@/assets/images/image23.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution116')}}</h4>
              <p>{{$t('solution117')}}</p>
            </div>
          </li>
          <li>
            <div class="img"><img src="@/assets/images/image26.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution118')}}</h4>
              <p>{{$t('solution119')}}</p>
            </div>
          </li>
          <li>
            <div class="img"><img src="@/assets/images/image4.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution120')}}</h4>
              <p>{{$t('solution121')}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <PlanBox />
      </div>
    </div>
  </div>
</template>
<script>
  import PlanBox from '@/components/PlanBox.vue'

export default {
  name: "SolutionGame",
  components: {
    PlanBox
  },
  mounted() {
  },
  data() {
    return {
    }
  },
  metaInfo() {
    return {
      title: '「BoxCDN」'+this.$t('solutionGame')+'-'+this.$t('home80')
    }
  }
}
</script>
<style lang="scss" scoped>
  .banner {
    background-image: url('~@/assets/images/banner2.jpg');
  }
</style>
